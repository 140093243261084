/**
 * Do not remove any keys!!
 * The shape of this file is to remain the same so that all options are know.
 *
 * If a key does not have a value, set the value to undefined.
 */
module.exports = {
  communityName: "Pineridge Farms",
  gtagId: "G-QS4QXLPEH6",
  logo: {
    src: "https://cdn.maxxpm.com/pineridge/v3_green.png",
    alt: "Pineridge Farms logo",
  },
  nav: {
    bannerSubtitle: undefined,
    bannerTitle: undefined,
    bannerCTA: undefined,
  },
  footer: {
    address: "388 West 650 North",
    cityStateZip: "Payson, UT 84651",
    phone: "(385) 236-5514",
    copyrightYear: "2024",
    logo: "https://cdn.maxxpm.com/pineridge/v2_white.png",
  },
  listMarkerSrc: "https://cdn.maxxpm.com/pineridge/pr_marker.png",
  hasCalendlyPopup: false,
  calendlyLink: undefined,
  home: {
    banner: {
      image: "https://cdn.maxxpm.com/pineridge/pr_home.jpg",
      video: undefined,
      title: "Home is our Happy Place",
      subtitle: "Now Leasing!",
    },
    aboutUs: {
      paragraphs: [
        "The third oldest city in Utah County, Payson is rich with history, culture, and countless opportunities for growth and success in the future. As a new development in the heart of town, Pineridge Farms is in a position to take advantage of healthy wage growth and one of the youngest populations in the country. Nearby Mountain View Hospital and Revere Health Clinic offer closeby employment, and a 10-minute drive up I-15 to Spanish Fork gives residents access to shopping centers, such as Costco and Walmart, as well as delicious restaurants, excellent entertainment, banks, and other services.",
        "Onsite, residents at Pineridge Farms have access to an exclusive clubhouse, complete with a fitness and business center, a temperature-regulated outdoor pool, a playground, and expansive recreational green space. Our 2-bed, 2-bath smart home models include a spacious open kitchen with a modern living space, washer and dryer hook-ups, an oversized master bedroom, and other premier touches.",
      ],
      image: {
        src: "https://cdn.maxxpm.com/pineridge/pr_about.jpg",
        alt: "Staged Living Room",
      },
    },
    floorplanTitle: "Explore Our Floor Plan and Take a 3D Virtual Tour",
    floorplans: [
      {
        hasImage: true,
        image: {
          src: "https://cdn.maxxpm.com/pineridge/pr_fp.png",
          alt: "Pineridge Farms Floor Plan",
        },
        hasWalkthrough: true,
        walkthrough: {
          src: "https://my.matterport.com/show/?m=KF8YRtg4iNs",
          title: "2 bed 2 bath 3d walkthrough",
        },
      },
    ],
    midContainerImage: "https://cdn.maxxpm.com/pineridge/pr_mid.jpg",
    imgRow: [
      {
        src: "https://cdn.maxxpm.com/pineridge/Exteriors/DSC_0257.jpg",
        alt: "Pineridge Farms Exterior Photo",
      },
      {
        src: "https://cdn.maxxpm.com/pineridge/Exteriors/DSC_0289-Edit.jpg",
        alt: "Pineridge Farms Exterior Photo",
      },
      {
        src: "https://cdn.maxxpm.com/pineridge/Exteriors/DSC_0268-Edit.jpg",
        alt: "Pineridge Farms Exterior Photo",
      },
    ],
    animalPolicy: {
      img: {
        src: "https://cdn.maxxpm.com/pineridge/pr_farm.jpg",
        alt: "Animal policy stock photo",
      },
      policies: [
        "No more than 2 animals are allowed per property",
        "Cats/Dogs: $250/animal*",
        "$50/animal/month for animals under 50lbs*",
        "$75/animal/month for animals 50lbs+*",
        "One-time $75/animal DNA testing fee*",
        "Caged animals: $25/animal/month*",
        "Fish tanks are not permitted",
        "Rabbits and rodents of any kind are not permitted",
      ],
      breedRestrictions: [
        "Pit Bulls & Staffordshire Terriers",
        "Doberman Pinschers",
        "Rottweilers",
        "Chows",
        "Great Danes",
        "Presa Canarios/ Mastiffs",
        "Akitas",
        "Alaskan Malamutes",
        "Huskies",
        "German Shepherds",
        "Shar Peis",
        "Wolf-Hybrids",
      ],
    },
  },
  availability: {
    banner: {
      image: "https://cdn.maxxpm.com/pineridge/pr_home.jpg",
      video: undefined,
      title: "Available Units",
      subtitle:
        "Unit availability is updated often from Monday-Friday during business hours. Evenings and weekends may not reflect accurate availability dates.",
    },
    hasWaitingList: false,
    waitingListJotformId: undefined,
    propertyGroup: "LEADS-PF",
  },
  faq: {
    banner: {
      image: "https://cdn.maxxpm.com/pineridge/pr_home.jpg",
    },
    dnaRequired: true,
  },
};
